<template>
  <v-container class="fill-height notfication pt-120" fluid>
    <v-row class="fill-height mb-10" justify="center">
      <v-card
        color="white"
        class="rounded notfication-card"
        flat
        tile
        width="490"
      >
        <v-card-title class="text-h5">
          {{ $t('notifications') }}
        </v-card-title>
        <template v-if="loading">
          <v-skeleton-loader
            v-for="n in 5"
            :key="n"
            type="list-item-avatar-two-line"
          />
        </template>
        <div v-else>
          <template v-for="(notification, index) in notifications">
            <div :key="notification.id">
              <v-list-item :class="{ 'mt-n2': index != 0 }">
                <span class="text-body-1 text--opacity-50">{{
                  getTimeAgoDate(notification.createdOn)
                }}</span>
              </v-list-item>
              <v-list-item
                :class="{ 'mt-n1': index != 0, read: !notification.read }"
                @click="markNotificationRead(notification.id)"
              >
                <v-list-item-avatar
                  color="grey_regular mr-2"
                  class="rounded"
                  size="55"
                >
                  <v-icon color="black">
                    mdi-clock-time-five-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="mt-n1">
                  <v-list-item-title
                    class="text-subtitle-2 text--opacity-75 text-wrap"
                    v-html="`${notification.message}`"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider class="grey" />
            </div>
          </template>

          <v-row
            v-if="!isShowLoadMore"
            no-gutters
            align="center"
            justify="center"
            class="my-5"
          >
            <v-btn
              color="primary"
              :loading="loading"
              class="transition-ease-in-out white--text"
              :disabled="moreLoading"
              @click="getNotifications(true)"
            >
              {{ $t('load_more') }}
            </v-btn>
          </v-row>
        </div>
        <v-card-text
          v-if="!loading && notifications.length == 0"
          class="text-subtitle-1 text-center pt-10"
        >
          {{ $t('no_notification') }}
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// utils
import { getNotificationslist } from '@/utils/helpers.js'

export default {
  data() {
    return {
      notifications: [],
      loading: false,
      moreLoading: false,
      isShowLoadMore: false,
      page: 10,
      skip: 0,
    }
  },
  computed: {
    ...mapGetters(['isTalent', 'jwt', 'user']),
    getTimeAgoDate() {
      return date =>
        this.$moment(this.$moment(date).toISOString())
          .tz(this.$moment.tz.guess(Boolean))
          .fromNow()
    },
    computedDateFormatted() {
      return (date, dateFormat) =>
        this.$moment(this.$moment(date).toISOString())
          .tz(this.$moment.tz.guess(Boolean))
          .format(dateFormat)
    },
  },
  mounted() {
    this.getNotifications()
  },

  methods: {
    /**
     * mark read
     */
    async markNotificationRead(id) {
      await await this.$store.dispatch('markNotificationRead', {
        notificationId: id,
        markall: false,
      })

      this.notifications.forEach(data => {
        if (data.id == id) {
          data.read = true
        }
      })
    },
    /**
     * Get Notifications
     */
    async getNotifications(paging) {
      try {
        if (paging) {
          this.skip += this.page
          this.moreLoading = true
        } else {
          this.loading = true
        }

        const {
          notifications,
          totalNotifications,
        } = await getNotificationslist({
          email: this.user.email,
          token: this.jwt,
          pageNumber: this.page,
          skip: this.skip,
          notiForheader: false,
        })

        notifications.forEach(data => {
          this.notifications.push(data)
        })

        if (this.notifications.length == totalNotifications) {
          this.isShowLoadMore = true
        }

        this.moreLoading = false
        this.loading = false
      } catch (error) {
        this.loading = false
        this.moreLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.notfication {
  background-color: #f1f3f6;
}
.read {
  background: #d8d8d8b8;
}
</style>
